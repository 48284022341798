
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index_gPQvWBxdnf_45VVb1WF8t1UE_m5BACGRrLXH6sguIR3sMeta } from "/builds/bazinga/mfg/frontend/pages/index.vue?macro=true";
import { default as _91id_93G_nA6oAgA_8beAJ4nUCG_RSW4J7j7LiCcpK_4Wbyq9oMeta } from "/builds/bazinga/mfg/frontend/pages/form/[id].vue?macro=true";
import { default as indexHfyP02gyCixNALtgkj_45_BhXNZ5vL8EQZ5AxIRDL6ElYMeta } from "/builds/bazinga/mfg/frontend/pages/events/index.vue?macro=true";
import { default as indexSnCBhX_SQMtvzObJKC4ma_45dlh8webwSqeP5dH_fSktcMeta } from "/builds/bazinga/mfg/frontend/pages/search/index.vue?macro=true";
import { default as index8crRodqA_45QX_Nv_gctbw2KXgTxJI_458QQWC_45u99zXRUEMeta } from "/builds/bazinga/mfg/frontend/pages/calendar/index.vue?macro=true";
import { default as indexJk_Ys3bYBDTjN7oMiA67DiHe4TRw96w3_5J81z5VtngMeta } from "/builds/bazinga/mfg/frontend/pages/[...slug]/index.vue?macro=true";
import { default as indexGWwke_jiyPQH9qAytf1wVBeEdfawSTb_455Q71pc0Di_wMeta } from "/builds/bazinga/mfg/frontend/pages/node/[nid]/index.vue?macro=true";
import { default as indexaXc2nDqV_455eDR888mD4CHv794ZvxEdMZsbiIKG96DwsMeta } from "/builds/bazinga/mfg/frontend/pages/styleguide/index.vue?macro=true";
import { default as indexCup1s8nDQIfUnpBbaQ6TlVB4wvBIFV00kkBHXjRPpxAMeta } from "/builds/bazinga/mfg/frontend/pages/contact/[..slug]/index.vue?macro=true";
import { default as index3uKL3OM29eCn_XeB4rPO5M1ab_izsBoa7pKI0jvEsDwMeta } from "/builds/bazinga/mfg/frontend/pages/events/[...slug]/index.vue?macro=true";
import { default as _91id_939QZ5Qsl292_003_5mHsrWAvfcp_Fwzjd4jLJWsTKjmkMeta } from "/builds/bazinga/mfg/frontend/pages/form/confirmation/[id].vue?macro=true";
import { default as indexzKonFKB_x_45ufadUl0mbRXOYnZ6oNc4kWebZ2z4ihVJYMeta } from "/builds/bazinga/mfg/frontend/pages/article/[...slug]/index.vue?macro=true";
import { default as indexECb8UhCLePjz_LS8nPHcPLFvUa24gx_SXxJ5wKeDT0IMeta } from "/builds/bazinga/mfg/frontend/pages/events/series/[id]/index.vue?macro=true";
import { default as indexuBuokuGkDfBfpQw_6hhG39z6ektSkHg9UxvrcAlpw44Meta } from "/builds/bazinga/mfg/frontend/pages/events/[id]/register/index.vue?macro=true";
import { default as indexYY4DaCozuc5rhR9SZWmOKKkJli2xh3uth6_45ORGMi1UMMeta } from "/builds/bazinga/mfg/frontend/pages/exhibition/[...slug]/index.vue?macro=true";
import { default as _91uuid_93xOEWuYEAzPmVXm39FCX3pszicWqZtqfyz_YjXzxu_s4Meta } from "/builds/bazinga/mfg/frontend/pages/blokkli/library-item/[uuid].vue?macro=true";
export default [
  {
    name: "homepage",
    path: "/",
    meta: index_gPQvWBxdnf_45VVb1WF8t1UE_m5BACGRrLXH6sguIR3sMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/index.vue")
  },
  {
    name: "webform-route",
    path: "/form/:id()",
    meta: _91id_93G_nA6oAgA_8beAJ4nUCG_RSW4J7j7LiCcpK_4Wbyq9oMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/form/[id].vue")
  },
  {
    name: "admin-events",
    path: "/events",
    meta: indexHfyP02gyCixNALtgkj_45_BhXNZ5vL8EQZ5AxIRDL6ElYMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/events/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: indexSnCBhX_SQMtvzObJKC4ma_45dlh8webwSqeP5dH_fSktcMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/search/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar",
    meta: index8crRodqA_45QX_Nv_gctbw2KXgTxJI_458QQWC_45u99zXRUEMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/calendar/index.vue")
  },
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexJk_Ys3bYBDTjN7oMiA67DiHe4TRw96w3_5J81z5VtngMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "node-canonical",
    path: "/node/:nid()",
    meta: indexGWwke_jiyPQH9qAytf1wVBeEdfawSTb_455Q71pc0Di_wMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/node/[nid]/index.vue")
  },
  {
    name: "styleguide-page",
    path: "/styleguide",
    meta: indexaXc2nDqV_455eDR888mD4CHv794ZvxEdMZsbiIKG96DwsMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/styleguide/index.vue")
  },
  {
    name: "contact-..slug",
    path: "/contact/:..slug()",
    meta: indexCup1s8nDQIfUnpBbaQ6TlVB4wvBIFV00kkBHXjRPpxAMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/contact/[..slug]/index.vue")
  },
  {
    name: "eventinstance-default",
    path: "/events/:slug(.*)*",
    meta: index3uKL3OM29eCn_XeB4rPO5M1ab_izsBoa7pKI0jvEsDwMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/events/[...slug]/index.vue")
  },
  {
    name: "webform-confirmation",
    path: "/form/confirmation/:id()",
    meta: _91id_939QZ5Qsl292_003_5mHsrWAvfcp_Fwzjd4jLJWsTKjmkMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/form/confirmation/[id].vue")
  },
  {
    name: "article-detail",
    path: "/article/:slug(.*)*",
    meta: indexzKonFKB_x_45ufadUl0mbRXOYnZ6oNc4kWebZ2z4ihVJYMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/article/[...slug]/index.vue")
  },
  {
    name: "event-series-id",
    path: "/events/series/:id()",
    meta: indexECb8UhCLePjz_LS8nPHcPLFvUa24gx_SXxJ5wKeDT0IMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/events/series/[id]/index.vue")
  },
  {
    name: "event-register-form",
    path: "/events/:id()/register",
    meta: indexuBuokuGkDfBfpQw_6hhG39z6ektSkHg9UxvrcAlpw44Meta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/events/[id]/register/index.vue")
  },
  {
    name: "exhibition-detail",
    path: "/exhibition/:slug(.*)*",
    meta: indexYY4DaCozuc5rhR9SZWmOKKkJli2xh3uth6_45ORGMi1UMMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/exhibition/[...slug]/index.vue")
  },
  {
    name: "blokkli-library-item-uuid",
    path: "/blokkli/library-item/:uuid()",
    meta: _91uuid_93xOEWuYEAzPmVXm39FCX3pszicWqZtqfyz_YjXzxu_s4Meta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/blokkli/library-item/[uuid].vue")
  }
]