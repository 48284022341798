<template>
  <DrupalLink :to="to" class="group group/link">
    <div
      class="md:group-hover:opacity-90 transition overflow-hidden bg-stone-50 mb-[calc(theme(spacing.teaser)+theme(spacing.1))]"
    >
      <MediaImage
        v-if="media"
        v-bind="media"
        :image-style="imageStyle"
        hide-caption
        img-class="md:group-hover:scale-105 transition ease-swing"
        draggable="false"
      />
    </div>
    <p v-if="tagline" class="typo-teaser-text leading-none">
      {{ tagline }}
    </p>

    <h3
      class="typo-teaser-title font-bold transition md:group-hover:text-stone-800 text-balance mb-teaser"
    >
      {{ title }}
    </h3>

    <div
      v-if="text"
      class="md:group-hover:text-stone-800 transition typo-teaser-text"
      v-html="text"
    />

    <div
      v-if="$slots.default"
      class="md:group-hover:text-stone-800 transition typo-teaser-text leading-none"
    >
      <slot />
    </div>

    <p
      class="link-underline typo-teaser-text mt-teaser leading-none mb-1"
      :class="{
        'sr-only': hideDefaultLink && !linkText,
      }"
    >
      {{ linkText || $texts('more_information', 'Mehr Informationen')
      }}<span class="sr-only">
        {{ $texts('accessibility.about', 'auf') }}
        {{ title }}
      </span>
    </p>
  </DrupalLink>
</template>

<script lang="ts" setup>
import type { MediaImageFragment } from '#graphql-operations'
import type { AspectRatio } from '~/tailwind'

const { maxWidthForCols, getAspectRatio } = useTailwind()

const props = withDefaults(
  defineProps<{
    tagline?: string
    title?: string
    text?: string
    linkText?: string
    teaserText?: string
    to?: string
    media?: MediaImageFragment
    aspectRatio?: AspectRatio
    hideDefaultLink?: boolean
  }>(),
  {
    tagline: '',
    title: '',
    text: '',
    linkText: '',
    teaserText: '',
    to: '',
    media: undefined,
    aspectRatio: 'square',
  },
)

const imageStyle = computed(() =>
  defineImageStyle({
    type: 'sizes',
    aspectRatio: getAspectRatio(props.aspectRatio).number,
    sizes: {
      default: maxWidthForCols('default', 4),
      xs: maxWidthForCols('xs', 4),
      sm: maxWidthForCols('sm', 4),
      md: maxWidthForCols('md', 4),
      lg: maxWidthForCols('lg', 4),
    },
  }),
)
</script>
