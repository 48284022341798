<template>
  <nav
    v-if="tabs.length"
    ref="anchorNav"
    class="pointer-events-none"
    :aria-label="$texts('accessibility.in_page_navigation', 'Seitennavigation')"
  >
    <div class="container">
      <div ref="elTabs">
        <ul
          ref="elTabsList"
          class="ml-[-3px] grid gap-2 in-page-navigation-list"
        >
          <li
            v-for="item in tabs"
            :key="item.id"
            ref="tabItems"
            :data-tab-uuid="item.uuid"
          >
            <a
              :href="'#' + item.id"
              class="button is-anchor pointer-events-auto"
            >
              <SpriteSymbol name="arrow-down" />
              {{ item.label }}
              <span class="sr-only">
                {{
                  $texts(
                    'accessibility.link_internal',
                    'Direkt zum Abschnitt springen.',
                  )
                }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import type { FieldListItem } from '#blokkli/types'

interface AnchorItem {
  label: string
  id: string
  uuid?: string
}

const state = useState<Record<string, boolean>>(
  'anchor_link_visibility',
  () => {
    return {}
  },
)

const props = withDefaults(
  defineProps<{
    anchors?: AnchorItem[]
    paragraphs?: FieldListItem[]
    overlay?: boolean
  }>(),
  {
    anchors: () => [],
    paragraphs: () => [],
    overlay: false,
  },
)

const paragraphAnchors = defineAnchorLinks((v) =>
  v.addParagraphs(props.paragraphs),
)

const tabs = computed(() =>
  [...props.anchors, ...paragraphAnchors.value].filter((v) => {
    if (!v.uuid) {
      return true
    }

    // The visibility is set useing the defineAnchorLinkVisibility composable.
    // If it has not been set (undefined), assume that it's visible.
    const visibility = state.value[v.uuid]
    if (visibility === undefined) {
      return true
    }

    // Visibility was explicitly set.
    return visibility
  }),
)

defineOptions({
  name: 'InPageNavigation',
})
</script>
