import type { FieldListItem } from '#blokkli/types'
import { slugify } from '#vuepal/helpers/url'
import type { ParagraphFragment } from '#graphql-operations'

type FieldListItemTyped = FieldListItem & { props?: ParagraphFragment }

type AnchorLinkDefiner = {
  add: (
    label: string,
    id: string,
    condition?: ComputedRef<boolean>,
    uuid?: string,
  ) => AnchorLinkDefiner

  addParagraphs: (
    list?: FieldListItemTyped[] | undefined | null,
    conditions?: {
      event_exhibition?: ComputedRef<any>
    },
  ) => AnchorLinkDefiner
}

type AnchorLink = {
  label: string
  id: string
  uuid?: string
}

function isTruthyCheckbox(v: unknown): boolean {
  return v === 1 || v === true || v === '1' || v === 'true'
}

/**
 * Determine which title to use for the anchor link.
 */
function getAnchorTitle(item?: FieldListItemTyped) {
  if (!item) {
    return
  }

  // Reusable paragraph: We need to access the actual paragraph that was made
  // to be reusable.
  if (item.bundle === 'from_library') {
    return getAnchorTitle(item.props?.libraryItem?.block)
  }

  return item.props?.anchorTitle || item.props?.title
}

/**
 * Composable to define anchor links.
 */
export function defineAnchorLinks(
  cb: (definer: AnchorLinkDefiner) => void,
): ComputedRef<AnchorLink[]> {
  const { $texts } = useEasyTexts()

  return computed(function () {
    // Collect the defined data.
    const links: AnchorLink[] = []

    const definer: AnchorLinkDefiner = {
      add: function (
        label: string,
        id: string,
        condition?: ComputedRef<boolean>,
        uuid?: string,
      ) {
        if (condition && condition.value === false) {
          return this
        }
        links.push({ label, id, uuid })
        return this
      },

      addParagraphs: function (list, conditions) {
        if (!list) {
          return this
        }

        list.forEach((item) => {
          // Check whether the paragraph should be added as an anchor link.
          if (!isTruthyCheckbox(item.options?.showInAnchor)) {
            return
          }

          if (
            item.bundle === 'blokkli_fragment' &&
            item.props?.name === 'event_exhibition'
          ) {
            if (conditions && conditions.event_exhibition?.value) {
              this.add(
                $texts('event.related_exhibition', 'Passende Ausstellung'),
                'exhibition',
              )
            }
            return
          }

          // Determine the anchor title.
          const label = getAnchorTitle(item)
          if (!label) {
            return
          }

          const id = slugify(label)
          this.add(label, id, undefined, item.uuid)
        })

        return this
      },
    }

    cb(definer)

    return links
  })
}

export function defineAnchorLinkVisibility(uuid: string, visible: boolean) {
  const state = useState<Record<string, boolean>>(
    'anchor_link_visibility',
    () => {
      return {}
    },
  )

  state.value[uuid] = visible
}
