// --------------------------------------------------------------------------------
// Enums
// --------------------------------------------------------------------------------

/**
 * @example
 * ```graphql
 * enum EntityQueryOperator {
 *   BETWEEN
 *   CONTAINS
 *   ENDS_WITH
 *   EQUAL
 *   GREATER_THAN
 *   GREATER_THAN_OR_EQUAL
 *   IN
 *   IS_NOT_NULL
 *   IS_NULL
 *   LIKE
 *   NOT_BETWEEN
 *   NOT_EQUAL
 *   NOT_IN
 *   NOT_LIKE
 *   REGEXP
 *   SMALLER_THAN
 *   SMALLER_THAN_OR_EQUAL
 *   STARTS_WITH
 * }
 * ```
 */
export const EntityQueryOperator = Object.freeze({
  BETWEEN: 'BETWEEN',
  CONTAINS: 'CONTAINS',
  ENDS_WITH: 'ENDS_WITH',
  EQUAL: 'EQUAL',
  GREATER_THAN: 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
  IN: 'IN',
  IS_NOT_NULL: 'IS_NOT_NULL',
  IS_NULL: 'IS_NULL',
  LIKE: 'LIKE',
  NOT_BETWEEN: 'NOT_BETWEEN',
  NOT_EQUAL: 'NOT_EQUAL',
  NOT_IN: 'NOT_IN',
  NOT_LIKE: 'NOT_LIKE',
  REGEXP: 'REGEXP',
  SMALLER_THAN: 'SMALLER_THAN',
  SMALLER_THAN_OR_EQUAL: 'SMALLER_THAN_OR_EQUAL',
  STARTS_WITH: 'STARTS_WITH'
});

/**
 * @example
 * ```graphql
 * enum EntityType {
 *   """Kommentar"""
 *   COMMENT
 * 
 *   """Sprache"""
 *   CONFIGURABLE_LANGUAGE
 * 
 *   """Event Instance entity"""
 *   EVENTINSTANCE
 * 
 *   """Event series entity"""
 *   EVENTSERIES
 * 
 *   """Datei"""
 *   FILE
 * 
 *   """Medien"""
 *   MEDIA
 * 
 *   """Menü"""
 *   MENU
 * 
 *   """Benutzerdefinierter Menülink"""
 *   MENU_LINK_CONTENT
 * 
 *   """Inhalt"""
 *   NODE
 * 
 *   """Seitenabschnitt"""
 *   PARAGRAPH
 * 
 *   """Paragraphs Blokkli Edit State"""
 *   PARAGRAPHS_BLOKKLI_EDIT_STATE
 * 
 *   """Seitenabschnittsbibliothekseintrag"""
 *   PARAGRAPHS_LIBRARY_ITEM
 * 
 *   """Seitenabschnittstyp"""
 *   PARAGRAPHS_TYPE
 * 
 *   """Umleiten"""
 *   REDIRECT
 * 
 *   """Registrant"""
 *   REGISTRANT
 * 
 *   """Rokka Metadata"""
 *   ROKKA_METADATA
 * 
 *   """Taxonomie-Begriff"""
 *   TAXONOMY_TERM
 * 
 *   """Texte"""
 *   TEXTS
 * 
 *   """Translatable config pages"""
 *   TRANSLATABLE_CONFIG_PAGES
 * 
 *   """Benutzerprofil-Reiter"""
 *   USER
 * 
 *   """Ansicht"""
 *   VIEW
 * 
 *   """Webformular"""
 *   WEBFORM
 * }
 * ```
 */
export const EntityType = Object.freeze({
  /** Kommentar */
  COMMENT: 'COMMENT',
  /** Sprache */
  CONFIGURABLE_LANGUAGE: 'CONFIGURABLE_LANGUAGE',
  /** Event Instance entity */
  EVENTINSTANCE: 'EVENTINSTANCE',
  /** Event series entity */
  EVENTSERIES: 'EVENTSERIES',
  /** Datei */
  FILE: 'FILE',
  /** Medien */
  MEDIA: 'MEDIA',
  /** Menü */
  MENU: 'MENU',
  /** Benutzerdefinierter Menülink */
  MENU_LINK_CONTENT: 'MENU_LINK_CONTENT',
  /** Inhalt */
  NODE: 'NODE',
  /** Seitenabschnitt */
  PARAGRAPH: 'PARAGRAPH',
  /** Paragraphs Blokkli Edit State */
  PARAGRAPHS_BLOKKLI_EDIT_STATE: 'PARAGRAPHS_BLOKKLI_EDIT_STATE',
  /** Seitenabschnittsbibliothekseintrag */
  PARAGRAPHS_LIBRARY_ITEM: 'PARAGRAPHS_LIBRARY_ITEM',
  /** Seitenabschnittstyp */
  PARAGRAPHS_TYPE: 'PARAGRAPHS_TYPE',
  /** Umleiten */
  REDIRECT: 'REDIRECT',
  /** Registrant */
  REGISTRANT: 'REGISTRANT',
  /** Rokka Metadata */
  ROKKA_METADATA: 'ROKKA_METADATA',
  /** Taxonomie-Begriff */
  TAXONOMY_TERM: 'TAXONOMY_TERM',
  /** Texte */
  TEXTS: 'TEXTS',
  /** Translatable config pages */
  TRANSLATABLE_CONFIG_PAGES: 'TRANSLATABLE_CONFIG_PAGES',
  /** Benutzerprofil-Reiter */
  USER: 'USER',
  /** Ansicht */
  VIEW: 'VIEW',
  /** Webformular */
  WEBFORM: 'WEBFORM'
});

/**
 * Lists the types of institutions.
 * 
 * @example
 * ```graphql
 * """Lists the types of institutions."""
 * enum EventinstanceSchoolsRegisterFormInstitutionType {
 *   """Privatschule"""
 *   private
 * 
 *   """Öffentliche Schule"""
 *   public
 * }
 * ```
 */
export const EventinstanceSchoolsRegisterFormInstitutionType = Object.freeze({
  /** Privatschule */
  private: 'private',
  /** Öffentliche Schule */
  public: 'public'
});

/**
 * Lists the kantons of Switzerland.
 * 
 * @example
 * ```graphql
 * """Lists the kantons of Switzerland."""
 * enum EventinstanceSchoolsRegisterFormRegion {
 *   """Ausserhalb Stadt/Kanton Zürich"""
 *   other
 * 
 *   """Kanton Zürich (ohne Stadt)"""
 *   zurich_canton
 * 
 *   """Stadt Zürich"""
 *   zurich_city
 * }
 * ```
 */
export const EventinstanceSchoolsRegisterFormRegion = Object.freeze({
  /** Ausserhalb Stadt/Kanton Zürich */
  other: 'other',
  /** Kanton Zürich (ohne Stadt) */
  zurich_canton: 'zurich_canton',
  /** Stadt Zürich */
  zurich_city: 'zurich_city'
});

/**
 * Lists the school levels.
 * 
 * @example
 * ```graphql
 * """Lists the school levels."""
 * enum EventinstanceSchoolsRegisterFormSchoolLevel {
 *   """Sekundarstufe II"""
 *   higher_secondary
 * 
 *   """Sekundarstufe I"""
 *   lower_secondary
 * 
 *   """Vorschulstufe"""
 *   preschool
 * 
 *   """Primarstufe"""
 *   primary
 * 
 *   """Tertiärstufe"""
 *   tertiary
 * }
 * ```
 */
export const EventinstanceSchoolsRegisterFormSchoolLevel = Object.freeze({
  /** Sekundarstufe II */
  higher_secondary: 'higher_secondary',
  /** Sekundarstufe I */
  lower_secondary: 'lower_secondary',
  /** Vorschulstufe */
  preschool: 'preschool',
  /** Primarstufe */
  primary: 'primary',
  /** Tertiärstufe */
  tertiary: 'tertiary'
});

/**
 * The timeline status of an exhibition.
 * 
 * @example
 * ```graphql
 * """The timeline status of an exhibition."""
 * enum ExhibitionTimelineStatus {
 *   """The <em class="placeholder">Aktuell</em> timeline status."""
 *   CURRENT
 * 
 *   """The <em class="placeholder">Future</em> timeline status."""
 *   FUTURE
 * 
 *   """The <em class="placeholder">Past</em> timeline status."""
 *   PAST
 * }
 * ```
 */
export const ExhibitionTimelineStatus = Object.freeze({
  /** The <em class="placeholder">Aktuell</em> timeline status. */
  CURRENT: 'CURRENT',
  /** The <em class="placeholder">Future</em> timeline status. */
  FUTURE: 'FUTURE',
  /** The <em class="placeholder">Past</em> timeline status. */
  PAST: 'PAST'
});

/**
 * The relative status of the location at a specific time.
 * 
 * @example
 * ```graphql
 * """The relative status of the location at a specific time."""
 * enum OpeningHoursRelativeStatus {
 *   """After close"""
 *   AFTER_CLOSE
 * 
 *   """Before open"""
 *   BEFORE_OPEN
 * 
 *   """Closed all day"""
 *   CLOSED
 * 
 *   """Geöffnet"""
 *   OPEN
 * }
 * ```
 */
export const OpeningHoursRelativeStatus = Object.freeze({
  /** After close */
  AFTER_CLOSE: 'AFTER_CLOSE',
  /** Before open */
  BEFORE_OPEN: 'BEFORE_OPEN',
  /** Closed all day */
  CLOSED: 'CLOSED',
  /** Geöffnet */
  OPEN: 'OPEN'
});

/**
 * @example
 * ```graphql
 * enum ParagraphsBlokkliEditableFieldConfigType {
 *   frame
 *   plain
 * }
 * ```
 */
export const ParagraphsBlokkliEditableFieldConfigType = Object.freeze({
  frame: 'frame',
  plain: 'plain'
});

/**
 * @example
 * ```graphql
 * enum ParagraphsBlokkliRemoteVideoProvider {
 *   VIMEO
 *   YOUTUBE
 * }
 * ```
 */
export const ParagraphsBlokkliRemoteVideoProvider = Object.freeze({
  VIMEO: 'VIMEO',
  YOUTUBE: 'YOUTUBE'
});

/**
 * The type of registrant for an event instance.
 * 
 * @example
 * ```graphql
 * """The type of registrant for an event instance."""
 * enum RegistrantType {
 *   """The accessibility registrant type."""
 *   ACCESSIBILITY
 * 
 *   """The default registrant type."""
 *   DEFAULT
 * 
 *   """The privacy registrant type."""
 *   PRIVACY
 * 
 *   """The schools registrant type."""
 *   SCHOOLS
 * }
 * ```
 */
export const RegistrantType = Object.freeze({
  /** The accessibility registrant type. */
  ACCESSIBILITY: 'ACCESSIBILITY',
  /** The default registrant type. */
  DEFAULT: 'DEFAULT',
  /** The privacy registrant type. */
  PRIVACY: 'PRIVACY',
  /** The schools registrant type. */
  SCHOOLS: 'SCHOOLS'
});

/**
 * @example
 * ```graphql
 * enum WebformStateConditionTrigger {
 *   """Zwischen"""
 *   BETWEEN
 * 
 *   """Ausgewählt"""
 *   CHECKED
 * 
 *   """Leer"""
 *   EMPTY
 * 
 *   """Ausgefüllt"""
 *   FILLED
 * 
 *   """Größer als"""
 *   GREATER_THAN
 * 
 *   """Größer als/gleich"""
 *   GREATER_THAN_EQUAL_TO
 * 
 *   """Kleiner als"""
 *   LESS_THAN
 * 
 *   """Weniger als/gleich"""
 *   LESS_THAN_EQUAL_TO
 * 
 *   """Nicht zwischen"""
 *   NOT_BETWEEN
 * 
 *   """Kein Muster"""
 *   NOT_PATTERN
 * 
 *   """Muster"""
 *   PATTERN
 * 
 *   """Nicht ausgewähählt"""
 *   UNCHECKED
 * 
 *   """Wert ist"""
 *   VALUE_IS
 * 
 *   """Wert ist nicht"""
 *   VALUE_IS_NOT
 * }
 * ```
 */
export const WebformStateConditionTrigger = Object.freeze({
  /** Zwischen */
  BETWEEN: 'BETWEEN',
  /** Ausgewählt */
  CHECKED: 'CHECKED',
  /** Leer */
  EMPTY: 'EMPTY',
  /** Ausgefüllt */
  FILLED: 'FILLED',
  /** Größer als */
  GREATER_THAN: 'GREATER_THAN',
  /** Größer als/gleich */
  GREATER_THAN_EQUAL_TO: 'GREATER_THAN_EQUAL_TO',
  /** Kleiner als */
  LESS_THAN: 'LESS_THAN',
  /** Weniger als/gleich */
  LESS_THAN_EQUAL_TO: 'LESS_THAN_EQUAL_TO',
  /** Nicht zwischen */
  NOT_BETWEEN: 'NOT_BETWEEN',
  /** Kein Muster */
  NOT_PATTERN: 'NOT_PATTERN',
  /** Muster */
  PATTERN: 'PATTERN',
  /** Nicht ausgewähählt */
  UNCHECKED: 'UNCHECKED',
  /** Wert ist */
  VALUE_IS: 'VALUE_IS',
  /** Wert ist nicht */
  VALUE_IS_NOT: 'VALUE_IS_NOT'
});

/**
 * @example
 * ```graphql
 * enum WebformStateLogic {
 *   AND
 *   OR
 *   XOR
 * }
 * ```
 */
export const WebformStateLogic = Object.freeze({
  AND: 'AND',
  OR: 'OR',
  XOR: 'XOR'
});