import { defineNuxtPlugin } from '#app'

function getScript(id: string) {
  return `
 var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://analytics.zhdk.ch/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '${id}']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
`
}

export default defineNuxtPlugin({
  name: 'shkb-analytics',
  setup(nuxtApp) {
    const { domain } = useDomain()
    const id = domain === DomainType.MUSEUM ? '229' : '321'

    // Don't include the script stuff while developing.
    if (!import.meta.dev) {
      useHead({
        script: [
          {
            key: 'analytics-load',
            innerHTML: getScript(id),
            tagPriority: 9003,
          },
        ],
      })
    }

    // Where the dataLayer stuff is stored during SSR. We attach it to the
    // head after the page was rendered.
    const ssrDataLayer = useState<any[]>('ssrDataLayer', () => [])

    if (import.meta.server) {
      nuxtApp.hook('app:rendered', (ctx) => {
        ctx.ssrContext?.head.push({
          script: [
            {
              innerHTML: `window._paq = ${JSON.stringify(ssrDataLayer.value)}`,
              tagPriority: -100,
            },
          ],
        })
        ssrDataLayer.value = []
      })
    }

    const router = useRouter()
    const { trackSwitchPage } = useAnalytics()
    const ssrTrackedUrl = useState('ssr_tracked_url', () => '')

    if (import.meta.server) {
      // The event is emitted by useDrupalRoute().
      nuxtApp.hook('drupal-route', (ctx) => {
        if (!ctx.drupalRoute) {
          return
        }

        trackSwitchPage(router.currentRoute.value.fullPath, ctx.metatags.title)
        ssrTrackedUrl.value = router.currentRoute.value.fullPath
      })
    }

    if (import.meta.client) {
      let timeout: null | number = null
      let lastSet = ''

      router.afterEach((to) => {
        if (ssrTrackedUrl.value === to.fullPath) {
          return
        }
        ssrTrackedUrl.value = ''
        if (timeout) {
          window.clearTimeout(timeout)
        }
        timeout = window.setTimeout(() => {
          if (lastSet !== to.fullPath) {
            trackSwitchPage(to.fullPath, document.title)
            lastSet = to.fullPath
          }
        }, 1000)
      })
    }
  },
})
