<template>
  <section
    v-if="(eventsList && eventsList?.events?.length) || isEditing"
    :id="anchorId"
    class="mt-section"
  >
    <h2 class="h2 container section-title">
      {{ title }}
    </h2>

    <div class="border-b-1 border-stone-50">
      <div
        v-for="event in eventsList?.events"
        :key="event.uuid"
        class="border-t-1 border-stone-50 py-4 md:py-6 lg:py-10"
      >
        <EventTeaser class="container" v-bind="event" />
      </div>
    </div>

    <div v-if="calendarUrl" class="container mb-6 mt-8 md:mt-10">
      <NuxtLink :to="calendarUrl" class="button">
        {{ $texts('dynamic_list_event.showAllEvents', 'alle Veranstaltungen') }}
      </NuxtLink>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { slugify } from '#vuepal/helpers/url'
import type { RouteLocationRaw } from 'vue-router'
import type { ParagraphDynamicListEventFragment } from '#graphql-operations'

const props = defineProps<ParagraphDynamicListEventFragment>()

const language = useCurrentLanguage()

const anchorId = computed(() => slugify(props.anchorTitle || props.title))

const { isEditing, uuid } = defineBlokkli({
  bundle: 'dynamic_list_event',
  globalOptions: ['showInAnchor'],
})

const { data: eventsList } = await useAsyncGraphqlQuery(
  'getEvents',
  {
    parentType: props.parentType || '',
    parentId: props.parentId,
  },
  {
    graphqlCaching: {
      client: true,
    },
    transform: function (data) {
      return data.data.events
    },
  },
)

const hasEvents = computed(() => !!eventsList.value?.events?.length)

const { buildQuery } = useCalendar()

const calendarUrl = computed<RouteLocationRaw | undefined>(() => {
  // This is the case if we are already displaying all events that exist.
  if (!eventsList?.value?.canLoadMore) {
    return
  }

  return {
    name: 'calendar___' + language.value,
    query: buildQuery(eventsList.value.filters),
  }
})

// On the client side, reactively set whether the anchor link is visible or not.
if (import.meta.client) {
  defineAnchorLinkVisibility(uuid, hasEvents.value)
}

// On the server, the anchor link is always rendered, but we hide it with CSS.
// Else we would have hydration errors.
if (import.meta.server && !hasEvents.value) {
  useHead({
    style: [
      `[data-tab-uuid="${uuid}"] {
display: none;
}`,
    ],
  })
}

defineOptions({
  name: 'ParagraphDynamicListEvent',
})
</script>
